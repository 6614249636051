import getEnv from "../components/env";

export function linkClicked(element, result, location) {
  report(element, "Clicked", result, location);
}

/**
 * @param {string} solutionId
 *
 * @see https://coxautoinc.sharepoint.com/sites/department-user-experience/SitePages/Common%20Data%20Layer%20Variables%20schema%20for%20Google%20Tag%20Manager.aspx
 */
export function initializeDataLayer(solutionId) {
  const env = getEnv();
  window.dataLayer.push({
    common: {
      dataLayerVersion: 2,
      application: {
        name: "Signin",
        version: "1",
        environment: env,
        isProduction: env === "live"
      }
    },
    solutionId,
    sessionControl: "start"
  });
  window.dataLayer.push({ event: "dataLayerReady" });
}

/**
 * Identifies the user to the data layer. Once the user is identified all subsequent
 * events will be associated with this user. The tag manager script effectively treats
 * the array of objects added to the datalayer as a single merged object, where each
 * push will add to or overwrite existing values.
 *
 * @param {string} bridgePlatformId
 * @param {string} bridgeUsername
 * @param {string} solutionPrincipalId
 */
export function identifyUser({
  bridgePlatformId,
  bridgeUsername,
  solutionPrincipalId
}) {
  window.dataLayer.push({
    common: {
      user: {
        bridgePlatformId,
        bridgeUsername,
        solutionPrincipalId
      }
    }
  });
}

export function endSession() {
  window.dataLayer.push({ sessionControl: "end" });
}

/*
Defined by https://fuel.coxautoinc.com/docs/DOC-145376
Location - A breadcrumb trail of nested DOM components. These values should be consistent across events such that all
           user interactions within a given component have the same breadcrumb trail. The top-most item should break down
           into a reasonable number of application features.
Element - The name of an element the user interacted with, from their perspective. This should be provided in readable
          English, i.e. passing in "Tab" as opposed to "li" or other DOM/code type identifiers (including classes and IDs).

Action - The action that triggered the data collection (clicked, hovered, dragged, etc). This should be in readable
         English and past tense.
Result - What occurred from the user's perspective (opened the date selector, etc). This should be in readable English
         and past tense for consistency across applications. Ideally, the value here would be the actual result of the
         user interaction, not simply a guess. For example, if a user kicked off an ajax process, if that process fails,
         the result should articulate that.

Value - Any quantitative value associated with the action (a count of returned search results, etc).
 */
export function report(element, action, result, location) {
  window.dataLayer.push({
    event: "userEvent",
    eventElement: element,
    eventAction: action,
    eventResult: result,
    eventLocation: location,
    eventValue: undefined
  });
}

/**
 * Publishes a new product event to the data layer.
 *
 * @param {string} name
 * @param {object} properties
 *
 * @see https://coxautoinc.sharepoint.com/sites/department-user-experience/SitePages/User%20Event%20Tracking%20with%20Google%20Tag%20Manager.aspx
 */
export function productEvent(name, properties = {}) {
  window.dataLayer.push({
    event: "productEvent",
    eventName: name,
    eventProperties: properties
  });
}
